import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatInput, MatLabel } from '@angular/material/input';

@Component({
    selector: 'faw-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    imports: [
        MatDialogClose,
        MatButton,
        MatDialogActions,
        MatDialogContent,
        MatDialogTitle,
        ReactiveFormsModule,
        MatInput,
        MatLabel,
        MatFormField,
    ]
})
export class ModalComponent {
  public form = new FormGroup({ login: new FormControl<string>(null, Validators.required) })

  public data: {
    kind: string;
    title?: string;
    message: string;
    inputValue?: string;
  }  = inject(MAT_DIALOG_DATA);

  private dialogRef = inject<MatDialogRef<ModalComponent>>(MatDialogRef);


  public close() {
    this.dialogRef.close(this.form.value.login);
  }
}
