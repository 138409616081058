@switch (data.kind) {
  @case ('passwordReset') {
    <h1 mat-dialog-title>Neues Passwort anfordern</h1>

    <mat-dialog-content>
      <form [formGroup]="form" (ngSubmit)="close()">
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Login oder E-Mailadresse&nbsp;</mat-label>
          <input matInput formControlName="login" autocomplete="off">
        </mat-form-field>
      </form>
    </mat-dialog-content>

    <div mat-dialog-actions>
      <button mat-button mat-dialog-close>Abbrechen</button>
      <button mat-button [disabled]="!form.valid" [mat-dialog-close]="form.value.login">Ok</button>
    </div>
  }
  @case ('genericForm') {
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <mat-dialog-content>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>{{ data.title }}</mat-label>
        <input #input matInput autocomplete="off" [value]="data.inputValue || ''">
      </mat-form-field>
    </mat-dialog-content>
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="false">Cancel</button>
      <button mat-button [mat-dialog-close]="input.value">Ok</button>
    </div>
  }
  @default {
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content>
      {{ data.message }}
    </div>
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="false">Cancel</button>
      <button mat-button [mat-dialog-close]="true">Ok</button>
    </div>
  }
}


