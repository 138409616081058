import { inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '@core/components/common/modal/modal.component';

@Injectable({ providedIn: 'root' })
export class ModalService {
  public dialogRef = inject(MatDialogRef<ModalComponent>);
  private dialogConfig = new MatDialogConfig();

  private dialog = inject(MatDialog);

  constructor() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
  }

  public open<T>(config: MatDialogConfig): Promise<T> {
    config = { ...this.dialogConfig, ...config };
    this.dialogRef = this.dialog.open(ModalComponent, config);

    return firstValueFrom(this.dialogRef.afterClosed()) as Promise<T>;
  }
}
