import { Injectable, signal, WritableSignal } from '@angular/core';
import { NOTIFICATION_TIMEOUT } from '@core/constants';
import { Notification, NotificationTypeEnum } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public $notifications: WritableSignal<Notification[]> = signal([]);
  private timeouts: { [key: string]: any } = {};

  constructor() {}

  public show(message: string, type: NotificationTypeEnum = NotificationTypeEnum.INFO, headline: string = null) {
    const id: string = crypto.randomUUID();
    const notification: Notification = { id, headline, message, type };

    this.addNotification(notification);

    this.timeouts[notification.id] = setTimeout(() => {
      this.fade(notification.id);
    }, NOTIFICATION_TIMEOUT);
  }

  public fade(id: string): void {
    this.$notifications.update(notifications => {
      notifications.find(notification => notification.id === id).cssExtra = 'fade';
      return notifications;
    });

    setTimeout(() => {
      this.removeNotification(id);
    }, 800);
  }

  public removeNotification(id: string): void {
    this.$notifications.update(notifications => notifications.filter(notification => notification.id !== id));
    delete this.timeouts[id];
  }

  private addNotification(notification: Notification): void {
    this.$notifications.update(notifications => [...notifications, notification]);
  }
}
