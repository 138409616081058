import { computed, effect, inject, Injectable, Signal } from '@angular/core';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { RootState } from '@store/index';
import { actions as userActions, selectors as userSelectors } from '@store/user';

import { IUser, User } from '@core/models';

import { ApiService, IApiResponse } from '../api/api.service';

import { LOCALSTORAGE_TOKEN_KEY, LOCALSTORAGE_USER_IDENTIFIER_KEY } from '@core/constants';
import { compareEntities } from '@lib/helpers';

@Injectable({ providedIn: 'root' })
export class UserService {
  public user: Signal<User>;
  public isAdmin = computed(() => this.user()?.role === 'admin');
  public isLoggedIn = computed(() => !!this.token && !!this.user());

  private store = inject<Store<RootState>>(Store);
  private apiService = inject(ApiService);

  constructor() {
    this.user = this.store.selectSignal(userSelectors.selectUser, { equal: compareEntities });
    effect(() => {
      const identifier = this.user()?.identifier;
      if (identifier && !this.userIdentifier) {
        this.userIdentifier = identifier;
      }
    });
  }

  get token(): string {
    return localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
  }

  set userIdentifier(identifier: string) {
    localStorage.setItem(LOCALSTORAGE_USER_IDENTIFIER_KEY, identifier ?? '');
  }

  get userIdentifier(): string | null {
    return localStorage.getItem(LOCALSTORAGE_USER_IDENTIFIER_KEY) ?? null;
  }

  public init(): Promise<boolean> {
    return this.restoreUser();
  }

  public passwordReset(login: string): Observable<IApiResponse<{ message: string; success: boolean }>> {
    return this.apiService.post<{ message: string; success: boolean }>('users/reset', { login });
  }

  private restoreUser(): Promise<boolean> {
    if (!this.token) {
      this.store.dispatch(userActions.logout());
      return Promise.resolve(false);
    }

    return new Promise(resolve => {
      this.apiService
        .post<{ user: IUser }>('users/check')
        .pipe(take(1))
        .subscribe(({ payload, error }) => {
          if (error) {
            return resolve(false);
          }

          this.store.dispatch(userActions.setCurrent({ user: payload.user }));

          resolve(true);
        });
    });
  }
}
